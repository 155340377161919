import {useAuth, guestHost} from '#imports';
import {useNuxtApp, defineNuxtRouteMiddleware} from '#app';
import {safelyNavigateTo} from '#malt/nuxt-utils-module';

export default defineNuxtRouteMiddleware(async () => {
    const nuxtApp = useNuxtApp();
    const store = useAuth(nuxtApp.$pinia);
    const host = guestHost();

    const isLogged = await store.isLogged();

    if (isLogged) {
        const path = store.currentIdentityIsClient
            ? '/project-client/find-freelancers'
            : store.currentIdentityIsFreelance
            ? '/dashboard/freelancer'
            : '/account/membership';
        return await safelyNavigateTo(`${host}${path}`, {replace: true, externalToThisApp: true});
    }
});
